<template>
  <div class="container">
    <div id="map" style="width:100%;height:100%"></div>
    <div class="btnmode" @click="showLayer">
      <span v-if="satelliteMode">标准</span>
      <span v-else>卫星</span>
    </div>
  </div>
</template>
<script>
let map;
let satellite;
var AMap = window.AMap;
var labelsLayer = new AMap.LabelsLayer({
  zooms: [3, 20],
  zIndex: 1000,
  // 该层内标注是否避让
  collision: false,
  // 设置 allowCollision：true，可以让标注避让用户的标注
  allowCollision: true
});
export default {
  props: ["positions"],
  data() {
    return {
      map: "",
      markerLayer: "",
      label: "",
      satelliteMode: false
    };
  },
  mounted() {
    this.initMap();
  },
  methods: {
    initMap() {
      map = new AMap.Map("map", {
        zoom: 11, //设置地图显示的缩放级别
        layers: [
          new AMap.createDefaultLayer(),
          // 卫星
          // new AMap.TileLayer.Satellite(),
          // // 路网
          // new AMap.TileLayer.RoadNet(),
          new AMap.TileLayer.Traffic({
            zIndex: 10,
            zooms: [7, 22]
          })
        ]
      });
      satellite = new AMap.TileLayer.Satellite({ zoom: 14 });
      this.setMarker();
    },
    setMarker() {
      let positions = this.positions;
      let lbMarkers = [];
      positions.forEach(element => {
        // let img = require("@/assets/sighticon0.png");
        var text = {
          // 要展示的文字内容
          content: element.name,
          // 文字方向，有 icon 时为围绕文字的方向，没有 icon 时，则为相对 position 的位置
          direction: "bottom",
          // 在 direction 基础上的偏移量
          offset: [0, -5],
          // 文字样式
          style: {
            // 字体大小
            fontSize: 11,
            // 字体颜色
            fillColor: "#22886f",
            // 描边颜色
            strokeColor: "#fff",
            // 描边宽度
            strokeWidth: 2
          }
        };
        let iconPath = element.iconPath.replace("/ast", "");
        let image = require("@/assets" + iconPath);
        var icon = {
          // 图标类型，现阶段只支持 image 类型
          type: "image",
          // 图片 url
          image: image,
          // 图片尺寸
          size: [20, 20]
          // 图片相对 position 的锚点，默认为 bottom-center
        };
        var labelMarker = new AMap.LabelMarker({
          name: element.id, // 此属性非绘制文字内容，仅最为标识使用
          position: [element.longitude, element.latitude],
          zIndex: 16,
          // 将第一步创建的 icon 对象传给 icon 属性
          icon: icon,
          // 将第二步创建的 text 对象传给 text 属性
          text: text
        });
        lbMarkers.push(labelMarker);
      });
      this.lbMarkers = lbMarkers;
      labelsLayer.add(lbMarkers);
      map.add(labelsLayer);
      map.setFitView();
    },
    showLayer() {
      if (this.satelliteMode) {
        map.removeLayer(satellite);
        this.satelliteMode = false;
      } else {
        this.satelliteMode = true;
        map.addLayer(satellite);
      }
    }
  },
  destroyed() {
    labelsLayer.remove(this.lbMarkers);
    map && map.destroy();
    console.log('map destroyed')
  }
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 500px;
  position: relative;
}

.btnmode {
  position: absolute;
  right: 40px;
  top: 20px;
  padding: 5px 10px;
  background: #fff;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  box-shadow: 0 0 5px #999;
}
</style>