<template>
  <div>
    <el-tabs v-model="activeName" type="border-card">
      <el-tab-pane label="景区信息" name="first">
        <div class="adminhead">
          <div class="headtitle headtip">景区设置</div>
          <div class="headtitle">
            <div>网络售票</div>
            <div style="margin:0 30px">
              <span v-if="sight.ticketSell" style="color:darkseagreen;">正在网络售票</span>
              <span v-else style="color:red">网络售票已关闭</span>
            </div>
            <div>
              <i class="el-icon-edit-outline" @click="changeTicketSell"></i>
            </div>
          </div>
          <div class="headtitle">
            <div>限流</div>
            <div style="margin:0 30px;font-weight:initial">
              <span v-if="sight.numLimit>0">{{sight.numLimit}} 张票/天</span>
              <span v-else>不限制每日人数</span>
            </div>
            <div @click="numLimitShow=true">
              <i class="el-icon-edit-outline"></i>
            </div>
          </div>
          <div class="headtitle">
            <div>景区开放</div>
            <div style="margin:0 30px">
              <span v-if="sight.state==2" style="color:darkseagreen;">对外开放</span>
              <span v-else style="color:red">暂时关闭</span>
            </div>
            <div>
              <i class="el-icon-edit-outline" @click="closeSight"></i>
            </div>
          </div>
        </div>
        <el-row :gutter="100" style="margin:50px 0">
          <el-col :span="12">
            <div v-if="editSightShow" style="padding: 20px 0">
              <el-form
                ref="editContent"
                :model="editContent"
                :rules="rules"
                label-width="80px"
                hide-required-asterisk
                size="small"
              >
                <el-form-item label="景点名称" prop="name">
                  <el-input v-model="editContent.name" maxlength="20" placeholder="必填，20字以内"></el-input>
                </el-form-item>
                <el-form-item label="景点介绍" prop="intro">
                  <el-input
                    v-model="editContent.intro"
                    autosize
                    type="textarea"
                    placeholder="必填，10字以上"
                  ></el-input>
                </el-form-item>
                <el-form-item label="景点类别">
                  <el-select v-model="editContent.type" placeholder="请选择">
                    <el-option
                      :label="item.name"
                      :value="item.value"
                      v-for="(item,index) in sightTypes"
                      :key="index"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="自驾车">
                  <el-select v-model="editContent.carIn">
                    <el-option label="免费进入" :value="0"></el-option>
                    <el-option label="收费进入" :value="1"></el-option>
                    <el-option label="禁止进入" :value="2"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="星级">
                  <el-select v-model="editContent.stars">
                    <el-option label="未评级" value="未评级"></el-option>
                    <el-option label="1A" value="1A"></el-option>
                    <el-option label="2A" value="2A"></el-option>
                    <el-option label="3A" value="3A"></el-option>
                    <el-option label="4A" value="4A"></el-option>
                    <el-option label="5A" value="5A"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开放时间">
                  <el-row>
                    <el-col :span="11">
                      <el-time-select
                        placeholder="起始时间"
                        v-model="editContent.start"
                        :picker-options="{ start: '00:00',step: '00:30',end: '24:00'}"
                      ></el-time-select>
                    </el-col>
                    <el-col :span="2" style="text-align:center">-</el-col>
                    <el-col :span="11">
                      <el-time-select
                        placeholder="结束时间"
                        v-model="editContent.end"
                        :picker-options="{ start: '00:00',step: '00:30',end: '24:00',minTime: editContent.start}"
                      ></el-time-select>
                    </el-col>
                  </el-row>
                </el-form-item>
                <el-form-item label="服务电话">
                  <el-input v-model="editContent.phone" placeholder="选填"></el-input>
                </el-form-item>
                <el-form-item label="区县">
                  <el-cascader
                    v-model="value"
                    :options="cities"
                    @change="handleChange"
                    size="small"
                    style="width:400px"
                  ></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" prop="address">
                  <el-input v-model="editContent.address" placeholder="必填"></el-input>
                </el-form-item>
                <el-form-item label="政策说明">
                  <el-input
                    v-model="editContent.instruction"
                    type="textarea"
                    autosize
                    placeholder="选填，景区政策、注意事项及防疫政策等"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button type="warning" size="small" @click="editSightShow=false">取消</el-button>
                  <el-button type="primary" size="small" @click="submitForm('editContent')">保存</el-button>
                </el-form-item>
              </el-form>
            </div>
            <table v-else>
              <thead>
                <td width="100px">名称</td>
                <td>内容</td>
                <td>
                  <i class="el-icon-edit-outline" @click="openTextEdit"></i>
                </td>
              </thead>
              <tr>
                <td>景点名称</td>
                <td>{{sight.name}}</td>
              </tr>
              <tr>
                <td>景区介绍</td>
                <td>
                  <pre>{{sight.intro}}</pre>
                </td>
              </tr>
              <tr>
                <td>类别</td>
                <td>{{sight.typeName}}</td>
              </tr>
              <tr>
                <td>星级</td>
                <td>{{sight.stars}}</td>
              </tr>
              <tr>
                <td>自驾车</td>
                <td>{{sight.carInName}}</td>
              </tr>
              <tr>
                <td>开放时间</td>
                <td>{{sight.start}} ~ {{sight.end}}</td>
              </tr>
              <tr>
                <td>服务电话</td>
                <td>
                  <span v-if="sight.phone">{{sight.phone}}</span>
                  <span v-else style="color:#999;font-size:13px">未填写</span>
                </td>
              </tr>
              <tr>
                <td>景点地址</td>
                <td>{{sight.province}}{{sight.city}}{{sight.district}}{{sight.address}}</td>
              </tr>
              <tr>
                <td>政策说明</td>
                <td>
                  <span v-if="sight.instruction">{{sight.instruction}}</span>
                  <span v-else style="color:#999;font-size:13px">未填写</span>
                </td>
              </tr>
            </table>
          </el-col>
          <el-col :span="12">
            <div class="subtitle" style="margin-bottom:40px">
              <span>入口定位</span>
              <i class="el-icon-edit-outline" style="margin:0 40px" @click="getGps(0)"></i>
            </div>

            <div class="subtitle">
              <span style="margin-right:40px">景区图片</span>
              <span
                v-if="editImgShow3"
                @click="editImgShow3=false"
                style="color:darkorange;cursor:pointer"
              >关闭</span>
              <i class="el-icon-edit-outline" @click="editImg(positions[0],3)" v-else></i>
            </div>
            <div v-if="editImgShow3">
              <div class="imgeditbox">
                <div v-for="(img,index) in editContent.imgs" :key="index" class="imgwrap">
                  <img :src="baseurl+img" alt class="imgwrap" @click="previewImg(img)" />
                  <div class="imgdeletewrap" @click="deleteImgConfirm(img)">
                    <i class="el-icon-delete-solid"></i>
                  </div>
                </div>
                <div v-if="percentage>0" class="imgwrap">
                  <el-progress type="circle" :percentage="percentage"></el-progress>
                </div>
              </div>
              <div style="margin-top:20px">
                <el-upload
                  action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                  :headers="headerObj"
                  :multiple="false"
                  :limit="20"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false"
                  :on-progress="uploadProgress"
                  :on-error="uploadError"
                >
                  <span v-if="editContent.imgs.length==20">已到图片数量上限</span>
                  <el-button v-else type="primary" size="mini" :disabled="percentage>0">点击上传图片</el-button>
                </el-upload>
              </div>
            </div>
            <div v-else>
              <img
                v-for="(item,index) in positions[0].imgs"
                :key="index"
                :src="baseurl+item"
                class="imgwrap"
                alt
                @click="previewImg(item)"
              />
            </div>
          </el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="位置点" name="second">
        <div class="adminhead">
          <div @click="openAddPosition" style="cursor:pointer;font-weight:bold">添加新位置点</div>
        </div>
        <!-- 添加新位置点 -->
        <div v-if="addpoitonShow" class="positionbox">
          <div class="addpoisitionbox">
            <el-form
              ref="editContent"
              :model="editContent"
              :rules="rules"
              label-width="80px"
              hide-required-asterisk
              size="small"
            >
              <el-form-item label="名称" prop="name">
                <el-input v-model="editContent.name" maxlength="20" placeholder="必填，20字以内"></el-input>
              </el-form-item>
              <el-form-item label="说明">
                <el-input v-model="editContent.intro" type="textarea" autosize placeholder="选填"></el-input>
              </el-form-item>
              <el-form-item label="类别" prop="type">
                <el-select v-model="editContent.type" placeholder="请选择">
                  <el-option
                    :label="item.name"
                    :value="item.value"
                    v-for="(item,index) in positionTypes"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="定位" prop="latitude">
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="percentage>0"
                  @click="getGps(-1)"
                >点击定位位置点</el-button>
                <span v-if="editContent.latitude" style="margin-left:20px;font-size:12px">成功定位</span>
              </el-form-item>
              <el-form-item label="上传图片" prop="imgs">
                <el-upload
                  action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                  :headers="headerObj"
                  :multiple="false"
                  :limit="20"
                  :before-upload="beforeAvatarUpload"
                  :on-success="handleAvatarSuccess"
                  :show-file-list="false"
                  :on-progress="uploadProgress"
                  :on-error="uploadError"
                >
                  <span v-if="editContent.imgs.length==20">已到图片数量上限</span>
                  <el-button v-else type="primary" size="mini" :disabled="percentage>0">点击上传图片</el-button>
                </el-upload>
              </el-form-item>
              <div class="imglistbox" style="margin:30px">
                <div v-for="(pic,index) in editContent.imgs" :key="index" class="imgwrap">
                  <img :src="baseurl+pic" alt class="imgwrap" @click="previewImg(pic)" />
                  <div class="imgdeletewrap" @click="deleteImgConfirm(pic)">
                    <i class="el-icon-delete-solid"></i>
                  </div>
                </div>
                <div v-if="percentage>0" class="imgwrap">
                  <el-progress type="circle" :percentage="percentage"></el-progress>
                </div>
              </div>
              <el-form-item>
                <el-button type="danger" plain size="small" @click="cancelAddPosition">取消</el-button>
                <el-button
                  type="primary"
                  size="small"
                  @click="submitForm('editContent')"
                  style="margin-left:30px"
                >保存</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <!-- 位置点列表 -->
        <div
          v-for="(item,index) in positions.filter(el=>el.type>0)"
          :key="index"
          class="positionbox"
        >
          <el-row :gutter="100">
            <el-col :span="12">
              <div>
                <span class="subtitle">名称</span>
                <span>{{item.name}}</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">类别</span>
                <span>{{item.typeName}}</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">说明</span>
                <span v-if="item.intro">{{item.intro}}</span>
                <span v-else style="color:#777;font-size:13px">未填写说明</span>
              </div>
              <div style="margin:30px 0">
                <span class="subtitle">操作</span>
                <span
                  style="color:#33cef4;cursor:pointer;margin-right:30px"
                  @click="positionEdit(item)"
                >编辑</span>
                <span
                  style="color:#33cef4;cursor:pointer;margin-right:30px"
                  @click="getGps(index-0+1)"
                >修改定位</span>
                <span
                  style="color:red;cursor:pointer"
                  @click="deletePositionConfirm(item)"
                >删除 {{item.name}}</span>
              </div>
            </el-col>
            <el-col :span="12">
              <div class="subtitle">
                <span style="margin-right: 40px">位置点图片</span>
                <span
                  v-if="editImgShow &editContent.id==item.id"
                  @click="editImgShow=false"
                  style="color:darkorange;cursor:pointer"
                >关闭</span>
                <i class="el-icon-edit-outline" @click="editImg(item,1)" v-else></i>
              </div>
              <div v-if="editImgShow &editContent.id==item.id">
                <div class="imgeditbox">
                  <div v-for="(pic,index) in editContent.imgs" :key="index" class="imgwrap">
                    <img :src="baseurl+pic" alt class="imgwrap" @click="previewImg(pic)" />
                    <div class="imgdeletewrap" @click="deleteImgConfirm(pic)">
                      <i class="el-icon-delete-solid"></i>
                    </div>
                  </div>
                  <div v-if="percentage>0" class="imgwrap">
                    <el-progress type="circle" :percentage="percentage"></el-progress>
                  </div>
                </div>
                <div style="margin-top:20px">
                  <el-upload
                    action="https://server.solar960.com/api/fileUploadWithThumbBiz"
                    :headers="headerObj"
                    :multiple="false"
                    :limit="20"
                    :before-upload="beforeAvatarUpload"
                    :on-success="handleAvatarSuccess"
                    :show-file-list="false"
                    :on-progress="uploadProgress"
                    :on-error="uploadError"
                  >
                    <span v-if="editContent.imgs.length==20">已到图片数量上限</span>
                    <el-button v-else type="primary" size="mini" :disabled="percentage>0">点击上传图片</el-button>
                  </el-upload>
                </div>
              </div>
              <div v-else>
                <img
                  v-for="(pic,index) in item.imgs"
                  :key="index+'i'"
                  :src="baseurl+pic"
                  class="imgwrap"
                  alt
                  @click="previewImg(pic)"
                />
              </div>
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>

      <el-tab-pane label="景区地图" name="third">
        <mapview :positions="positions" v-if="activeName=='third'" />
      </el-tab-pane>
    </el-tabs>
    <!-- 设置景区限流 -->
    <el-dialog :visible.sync="numLimitShow" destroy-on-close title="设置景区限流" :center="true">
      <div style="width:60%;margin:30px auto;text-align:center">
        <div class="inputbox">
          <div>当前：</div>
          <div v-if="sight.numLimit>0">
            <span>{{sight.numLimit}} 张票/天</span>
            <el-button
              type="success"
              plain
              size="mini"
              @click="setNumlimit(0)"
              style="margin-left:20px"
            >设置为不限制人数</el-button>
          </div>
          <div v-else>不限制每日人数</div>
        </div>
        <div class="inputbox">
          <div style="width:120px;margin-right:5px">每日限制人数</div>
          <el-input v-model="numLimit" placeholder="请输入每日限流人数，大于0整数"></el-input>
        </div>
        <el-button type="primary" size="small" @click="setNumlimit(1)">保存</el-button>
      </div>
    </el-dialog>
    <!-- 地图定位 -->
    <el-dialog :visible.sync="mapShow" width="80%" top="1vh">
      <latlngselect
        :lnglat="[editContent.longitude,editContent.latitude]"
        @location="setLocation"
        v-if="mapShow"
      />
    </el-dialog>
    <!-- 图片预览 -->
    <el-dialog :visible.sync="showImg" top="2vh" destroy-on-close>
      <img :src="baseurl+imgForPreview" width="100%" alt />
    </el-dialog>
    <!-- 编辑位置点名称和介绍 -->
    <el-dialog
      :visible.sync="showPositionEdit"
      top="15vh"
      destroy-on-close
      title="编辑位置点的名称和介绍"
      :center="true"
    >
      <div style="width:60%;margin:30px auto">
        <el-form
          ref="editContent"
          :model="editContent"
          :rules="rules"
          label-width="100px"
          hide-required-asterisk
          size="small"
        >
          <el-form-item label="位置点名称" prop="name">
            <el-input v-model="editContent.name" maxlength="10" placeholder="必填，10字以内"></el-input>
          </el-form-item>
          <el-form-item label="类别">
            <el-select v-model="editContent.type">
              <el-option
                :label="item.name"
                :value="item.value"
                v-for="(item,index) in positionTypes"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="位置点介绍">
            <el-input v-model="editContent.intro" type="textarea" autosize placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="submitForm('editContent')">保存</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getSightDetailPc,
  bizGetCityList,
  updateSightPc,
  updatePositionPc,
  updateTicketSell,
  deleteFileBiz,
  addPositionPc,
  getSightTypesPc
} from "@/api/api";
import latlngselect from "../components/LatlngSelect.vue";
import mapview from "../components/MapView.vue";

export default {
  components: { latlngselect, mapview },
  data() {
    return {
      baseurl: "https://server.solar960.com",
      headerObj: {
        Accept: "application/x.server.v4+json",
        Authorization: localStorage.getItem("Authorization")
      },
      activeName: "first",
      numLimitShow: false,
      mapShow: false,
      numLimit: "",
      sightTypes: [],
      positionTypes: [],
      editImgNum: 0,
      showPositionEdit: false,
      addpoitonShow: false,
      showImg: false,
      editImgShow: false,
      editImgShow3: false,
      percentage: 0,
      imgForPreview: "",
      sight: {},
      cities: [],
      value: [],
      getGpsType: "",
      positions: [{ imgs: [] }],
      editSightShow: false,
      editContent: {
        imgs: []
      },
      rules: {
        name: [{ required: true, message: "请输入景区名称", trigger: "blur" }],
        intro: [
          { required: true, message: "请输入景区介绍", trigger: "blur" },
          { min: 10, message: "不能低于10字", trigger: "blur" }
        ],
        latitude: [{ required: true, message: "请选择定位", trigger: "blur" }],
        type: [{ required: true, message: "请选择类别", trigger: "change" }],
        imgs: [{ required: true, message: "请上传图片", trigger: "blur" }],
        address: [
          { required: true, message: "请输入详细地址", trigger: "blur" }
        ]
      }
    };
  },
  mounted() {
    this.getSight();
    this.getSightTypesPcFn();
  },
  computed: {
    sightCompany() {
      return this.$store.getters.getBiz;
    }
  },
  methods: {
    // 获取景区详情
    getSight() {
      const that = this;
      getSightDetailPc().then(res => {
        if (res.result) {
          that.sight = res.data.sight;
          that.positions = res.data.positions;
        }
      });
    },
    getSightTypesPcFn() {
      const that = this;
      getSightTypesPc().then(res => {
        if (res.result) {
          that.sightTypes = res.data.sightTypes;
          that.positionTypes = res.data.positionTypes;
        }
      });
    },

    //表单检查
    submitForm(editContent) {
      this.$refs[editContent].validate(valid => {
        if (valid) {
          if (this.editType == "position") {
            this.updatePositionPcFn();
          } else if (this.editType == "newPosition") {
            this.addPositionPcFn();
          } else {
            this.saveSight();
          }
        } else {
          return false;
        }
      });
    },
    closeSight() {
      let text = "确认设置景区为关闭状态？";
      let state = 3;
      if (this.sight.state == 3) {
        text = "确认设置景区为开放状态？";
        state = 2;
      }
      this.$confirm(text, {
        type: "warning",
        center: true
      })
        .then(() => {
          this.editContent = {
            id: this.sight.id,
            state: state
          };
          this.saveSight();
        })
        .catch(() => {});
    },

    //更新景区信息
    saveSight() {
      const that = this;
      updateSightPc(that.editContent).then(res => {
        if (res.result) {
          that.editSightShow = false;
          that.editContent = {};
          that.numLimitShow = false;
          that.$message.success("修改成功");
          that.getSight();
        }
      });
    },

    //更改网络售票
    changeTicketSell() {
      const that = this;
      if (that.sight.ticketSell == 1) {
        let text = "";
        if (this.sight.numLimit > 0) {
          text = "关闭网络售票后，景区限流功能将不可用";
        }
        this.$confirm("确认关闭网络售票?" + text, {
          type: "warning"
        })
          .then(() => {
            let params = {
              id: that.sight.id,
              value: 0
            };
            that.updateTicketSellFn(params);
          })
          .catch(() => {});
      } else {
        this.$confirm("确认开始网络售票？", {
          type: "success"
        })
          .then(() => {
            let params = {
              id: that.sight.id,
              value: 1
            };
            that.updateTicketSellFn(params);
          })
          .catch(() => {});
      }
    },

    updateTicketSellFn(params) {
      const that = this;
      updateTicketSell(params).then(res => {
        if (res.result) {
          that.$message.success("修改成功");
          that.getSight();
        }
      });
    },

    //景点限流
    setNumlimit(type) {
      if (type == 0) {
        this.editContent = {
          id: this.sight.id,
          numLimit: 0
        };
      } else {
        if (this.numLimit == 0) {
          this.$alert("限制人数必须大于0", {
            type: "warning",
            callBack: () => {}
          });
          return;
        } else {
          this.editContent = {
            id: this.sight.id,
            numLimit: this.numLimit
          };
        }
      }
      this.saveSight();
    },

    //编辑景点信息
    openTextEdit() {
      this.editContent = { ...this.sight };
      this.editSightShow = true;
      this.editType = "sight";
      if (this.cities.length == 0) {
        this.getCityListFn();
      }
    },

    //预览图片
    previewImg(item) {
      this.imgForPreview = item.replace(".thumb", "");
      this.showImg = true;
    },

    // 打开位置点编辑
    positionEdit(item) {
      this.editContent = {
        id: item.id,
        name: item.name,
        intro: item.intro,
        type: item.type
      };
      this.editType = "position";
      this.showPositionEdit = true;
    },

    //城市列表
    getCityListFn() {
      const that = this;
      bizGetCityList({
        type: "district"
      }).then(res => {
        that.cities = res.data;
        let provinceIndex = res.data.findIndex(item => {
          return (
            item.adcode.toString().slice(0, 2) ==
            that.editContent.adcode.toString().slice(0, 2)
          );
        });
        let cityIndex = res.data[provinceIndex].children.findIndex(item => {
          return (
            item.adcode.toString().slice(0, 4) ==
            that.editContent.adcode.toString().slice(0, 4)
          );
        });
        that.value = [
          res.data[provinceIndex].value,
          res.data[provinceIndex].children[cityIndex].value,
          that.editContent.adcode
        ];
      });
    },
    // 选取城市
    handleChange(value) {
      let provinceIndex = this.cities.findIndex(item => {
        return item.adcode == value[0];
      });

      this.editContent.province = this.cities[provinceIndex].name;
      let cityIndex = this.cities[provinceIndex].children.findIndex(item => {
        return item.adcode == value[1];
      });
      this.editContent.city = this.cities[provinceIndex].children[
        cityIndex
      ].name;
      let districtIndex = this.cities[provinceIndex].children[
        cityIndex
      ].children.findIndex(item => {
        return item.adcode == value[2];
      });
      this.editContent.district = this.cities[provinceIndex].children[
        cityIndex
      ].children[districtIndex].name;
      this.editContent.adcode = value[2];
    },

    //重新定位
    getGps(index) {
      if (index == -1) {
        this.getGpsType = "addNew";
        this.editContent.longitude = this.positions[0].longitude;
        this.editContent.latitude = this.positions[0].latitude;
      } else {
        this.getGpsType = "update";
        this.editContent = {
          id: this.positions[index].id,
          longitude: this.positions[index].longitude,
          latitude: this.positions[index].latitude
        };
      }
      this.mapShow = true;
    },

    //定位获取GPS
    setLocation(e) {
      this.editContent.longitude = e[0];
      this.editContent.latitude = e[1];
      if (this.getGpsType == "update") {
        this.updatePositionPcFn();
      } else {
        this.mapShow = false;
      }
    },

    // 更新位置点
    updatePositionPcFn() {
      const that = this;
      updatePositionPc(that.editContent).then(res => {
        if (res.result) {
          that.mapShow = false;
          that.showPositionEdit = false;
          that.editContent = {};
          this.$message.success("修改成功");
          that.getSight();
        } else {
          this.$message.error(res.message);
        }
      });
    },

    //删除位置点确认
    deletePositionConfirm(item) {
      const that = this;
      this.$confirm("确认删除" + item.name + "?", {
        type: "warning"
      })
        .then(() => {
          that.editContent = {
            id: item.id,
            delete: true
          };
          that.updatePositionPcFn();
        })
        .catch(() => {});
    },
    //显示添加位置点
    openAddPosition() {
      this.editContent = {
        name: "",
        intro: "",
        type: "",
        longitude: "",
        latitude: "",
        idSight: this.sight.id,
        idUser: this.sightCompany.idUser,
        imgs: []
      };
      this.editType = "newPosition";
      this.addpoitonShow = !this.addpoitonShow;
    },

    //提交新位置点
    addPositionPcFn() {
      const that = this;
      addPositionPc(that.editContent).then(res => {
        if (res.result) {
          that.addpoitonShow = false;
          that.editContent = {};
          that.getSight();
        } else {
          this.$alert(res.message, {
            type: "warning",
            callBack: () => {}
          });
        }
      });
    },

    //取消添加位置点
    cancelAddPosition() {
      if (this.editContent.imgs.length > 0) {
        this.editContent.imgs.forEach(element => {
          this.deleteFileBizFn(element);
        });
      }
      this.addpoitonShow = false;
    },

    // 编辑图片
    editImg(item, numImgs) {
      this.editImgNum = numImgs;
      this.editType = "editPosition";
      if (numImgs == 3) {
        this.editImgShow3 = true;
      } else {
        this.editImgShow = true;
      }
      this.editContent = {
        id: item.id,
        imgs: item.imgs
      };
    },

    deleteImgConfirm(file) {
      const that = this;
      if (
        this.editType == "editPosition" &&
        this.editContent.imgs.length == this.editImgNum
      ) {
        this.$alert("图片大于" + this.editImgNum + "张时才可以删除", {
          type: "warning",
          callBack: () => {}
        });
      } else {
        this.$confirm("确认删除？", {
          type: "warning"
        })
          .then(() => {
            that.deleteFileBizFn(file);
          })
          .catch(() => {});
      }
    },
    //删除图片
    deleteFileBizFn(file) {
      const that = this;
      file = file.replace("https://server.solar960.com", "");
      deleteFileBiz({
        file: file
      }).then(res => {
        if (res.result) {
          let index = that.editContent.imgs.findIndex(item => {
            return item == file;
          });
          that.editContent.imgs.splice(index, 1);
          if (that.editType == "editPosition") {
            that.updatePositionPcFn();
          }
        }
      });
    },
    //上传图片成功
    handleAvatarSuccess(res) {
      if (res.result) {
        let imgUrl = res.data.files;
        this.editContent.imgs.push(imgUrl);
        if (this.editType == "editPosition") {
          this.updatePositionPcFn();
        }
      } else {
        this.$message.error(res.message);
      }
      this.percentage = 0;
    },
    //图片上传检查
    beforeAvatarUpload(file) {
      const isJPG =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 10;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG/PNG 格式!");
      }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isJPG && isLt2M;
    },

    //上传进度
    uploadProgress(event) {
      this.percentage = parseInt(event.percent);
    },

    //上传图片错误
    uploadError() {
      this.$message({
        message: "上传失败",
        type: "danger"
      });
      this.percentage = 0;
    }
  },
  destroyed() {
    if (this.addpoitonShow) {
      this.cancelAddPosition();
    }
  }
};
</script>

<style scoped>
i {
  color: #4b8cf5;
}
.adminhead {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  background: #efefef;
}
.headtitle {
  font-weight: bold;
  margin-right: 100px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}
.headtip {
  border-right: 1px solid #777;
  padding-right: 20px;
}
.imglistbox {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.imgeditbox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.imgwrap {
  margin-right: 10px;
  margin-bottom: 10px;
  width: 120px;
  height: 120px;
  position: relative;
}

.imgdeletewrap {
  position: absolute;
  right: 5px;
  top: 5px;
  color: #000;
  background: #fff;
  padding: 2px 4px;
  border-radius: 100%;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table thead td {
  font-weight: bold;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
}
table tr td {
  padding: 15px 0;
  border-bottom: 1px solid #eee;
}

.subtitle {
  margin-bottom: 20px;
  font-weight: bold;
  margin-right: 30px;
}

.inputbox {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.addpoisitionbox {
  width: 50%;
  text-align: left;
  margin: 30px auto;
}

.positionbox {
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 30px;
  margin: 30px;
}
.instructionbox {
  margin: 40px auto;
  width: 40%;
  text-align: center;
}
</style>